import React from "react"
import styled from "styled-components"

const StyledPageImageHeader = styled.div`
  width: 100%;
  height: 55vh;
  background-color: whitesmoke;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(85%);
  }
`

const PageImageHeader = props => {
  return (
    <StyledPageImageHeader>
      <img src={props.imageHeader} alt={props.alt} />
    </StyledPageImageHeader>
  )
}

export default PageImageHeader
