import React from "react"
import AudioPlayerv2 from "./AudioPlayerv2"
import MainNav from "./MainNav"
import { GlobalStyles } from "./styles/GlobalStyles"
import Footer from "./Footer"

// import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles>
        <MainNav />
        <div>
          <main style={{ minHeight: "80vh" }}>{children}</main>
          <AudioPlayerv2 />
          <Footer />
        </div>
      </GlobalStyles>
    </>
  )
}

export default Layout
