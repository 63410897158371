import React from "react"
import ImageHeader from "../images/music-submission-header.jpeg"
import Layout from "../components/layout"
import styled from "styled-components"
import PageImageHeader from "../components/common/PageImageHeader"
import SectionTitle from "../components/common/SectionTitle"
import SEO from "../components/seo"

const StyledFormP = styled.div`
  max-width: 1000px;
  padding: 0.5rem;
  margin: 0 auto;
`

const StyledForm = styled.div`
  max-width: 800px;
  margin: 0 auto;
  /* border: 2px solid black; */

  button {
    background-color: #be1e2d;
    border: none;
    color: white;
    padding: 0.5rem;
    font-size: 1em;
    margin: 1rem 0;
  }

  input {
    width: 95%;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 0;
    border-radius: 8px;
    border: 1px solid black;
    font-size: 1em;
  }

  select {
    width: 95%;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 0;
    border-radius: 8px;
    border: 1px solid black;
    font-size: 1em;
  }

  textarea {
    width: 95%;
    height: 150px;
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 0;
    border-radius: 8px;
    border: 1px solid black;
    font-size: 1.3em;
  }

  p,
  h3,
  h4 {
    padding: 0.5rem;
    margin: 0;
  }
`

const MusicSubmission = () => {
  return (
    <>
      <SEO
        title="music submission"
        description="Submit your Nerdcore or Geek Rock music to be played live on air at Geek Life Radio."
      />
      <Layout>
        <PageImageHeader imageHeader={ImageHeader} />
        <SectionTitle title="Music Submission" />
        <StyledFormP>
          <p>
            Geek Life Radio always welcomes new talent in music. Please consider
            showcasing your original content on Geek Life Radio.
          </p>
          <p>
            Currently relevant genre or desired content are some of the
            variables considered to be played on Geek Life Radio. We are looking
            specifically for “Geek rock” or “Nerdcore” Music only. Any genre is
            acceptable. All submissions are juried.
          </p>
          <p>
            Geek Life Radio does not take ownership of your content. We will
            link to your desired point of purchase whenever your song is playing
            live or in the directory listing.{" "}
          </p>
          <p>Please allow 72 hours for jurying and feedback. </p>
          <p>We look forward to hearing from you!</p>
        </StyledFormP>
        <StyledForm>
          <form
            method="POST"
            name="music-submission"
            data-netlify="true"
            action="/music-submission-success"
          >
            <input type="hidden" name="form-name" value="music-submission" />
            <h3>Contact Info</h3>
            <input type="text" name="name" placeholder="name *" required />
            <input
              type="email"
              name="email"
              placeholder="contact email *"
              required
            />
            <h3>Band Info</h3>
            <input
              type="text"
              name="band-name"
              placeholder="band name"
              required
            />
            <h4>Band Logo</h4>
            <p>this will be added to your profile page</p>
            <input type="file" name="band-logo" />
            <br />
            Or
            <br />
            <input type="text" placeholder="Band logo URL" />
            <input type="text" name="website" placeholder="website url" />
            <p>About your band</p>
            <textarea placeholder="About your band"> </textarea>
            <h4>Music Info</h4>
            <p>
              We are happy to purchase music we would like to play. If there is
              anything specific you would like to submit please let us know.{" "}
            </p>
            <textarea placeholder="description of songs and or albulms you would like.">
              {" "}
            </textarea>
            <h4>Purchase Link</h4>
            <p>Where can we purchase your content. </p>
            <input
              type="text"
              name="purchase-link"
              placeholder="purchase url"
            />
            <h3>Social</h3>
            <p>
              Completed fields will be added to your profile page. Leave blank
              any that do not apply
            </p>
            <input type="text" name="public-email" placeholder="Public Email" />
            <input type="text" name="twitter" placeholder="Twitter" />
            <input type="text" name="facebook" placeholder="Facebook" />
            <input type="text" name="instagram" placeholder="Instagram" />
            <input type="text" name="pinterest" placeholder="Pinterest" />
            <input type="text" name="tumblr" placeholder="Tumblr" />
            <input type="text" name="linkedin" placeholder="LinkedIn" />
            <input type="text" name="youtube" placeholder="You Tube" />
            <input type="text" name="twitch" placeholder="Twitch" />
            <input type="text" name="patreon" placeholder="Patreon" />
            <h3>Music Services</h3>
            <p>Leave blank fileds that do not apply.</p>
            <input type="text" name="soundcloud" placeholder="Soundcloud Url" />
            <input type="text" name="bandcamp" placeholder="bandcamp Url" />
            <input type="text" name="itunes" placeholder="iTunes Url" />
            <input
              type="text"
              name="google-play"
              placeholder="Google Play Url"
            />
            <input type="text" name="audiomack" placeholder="audiomack Url" />
            <input type="text" name="spotify" placeholder="Spotify Url" />
            <button type="submit">Add my Music!</button>
          </form>
        </StyledForm>
      </Layout>
    </>
  )
}

export default MusicSubmission
