import React from "react"
import styled from "styled-components"

const StyledSectionTitle = styled.h1`
  font-family: "Orbitron", sans-serif;
  /* font-family: "Roboto", sans-serif; */
  border-bottom: 3px solid red;
  padding: 1rem;
  margin: 1rem;
  max-width: 370px;
  font-weight: normal;
`

const SectionTitle = props => {
  return <StyledSectionTitle>{props.title}</StyledSectionTitle>
}

export default SectionTitle
